import React from 'react';

export default function IconStar() {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="#4F4855"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.57622 1.99744C8.51762 1.89641 8.43351 1.81255 8.3323 1.75426C8.23109 1.69597 8.11635 1.66528 7.99956 1.66528C7.88276 1.66528 7.76802 1.69597 7.66682 1.75426C7.56561 1.81255 7.48149 1.89641 7.42289 1.99744L5.61356 5.1161C5.55089 5.22397 5.4648 5.31638 5.36165 5.38654C5.25849 5.45669 5.14091 5.50279 5.01756 5.52143L1.62622 6.03343C1.05689 6.1201 0.856891 6.83877 1.30089 7.20677L3.82689 9.29877C4.06022 9.49277 4.17022 9.79877 4.11356 10.0968L3.45289 13.5668C3.42943 13.69 3.44121 13.8174 3.48689 13.9342C3.53257 14.051 3.61029 14.1526 3.71112 14.2273C3.81194 14.3019 3.93179 14.3466 4.05687 14.3562C4.18195 14.3658 4.3072 14.3399 4.41822 14.2814L7.61156 12.6001C7.7312 12.5372 7.86437 12.5043 7.99956 12.5043C8.13475 12.5043 8.26791 12.5372 8.38756 12.6001L11.5809 14.2814C11.6919 14.3399 11.8172 14.3658 11.9422 14.3562C12.0673 14.3466 12.1872 14.3019 12.288 14.2273C12.3888 14.1526 12.4665 14.051 12.5122 13.9342C12.5579 13.8174 12.5697 13.69 12.5462 13.5668L11.8856 10.0968C11.8576 9.95027 11.8694 9.79895 11.9198 9.65858C11.9702 9.51822 12.0574 9.39396 12.1722 9.29877L14.6989 7.2061C15.1422 6.83877 14.9422 6.11943 14.3722 6.03343L10.9822 5.52143C10.8588 5.50288 10.741 5.45683 10.6378 5.38667C10.5345 5.31651 10.4483 5.22405 10.3856 5.1161L8.57622 1.99744Z" />
      </svg>
    </>
  );
}
