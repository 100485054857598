import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function Connection({ allData }) {
  const { tag, heading, subheading } = allData;
  const allConnections = allData.boxCard;

  return (
    <>
      <div className="connection">
        <div className="wrapper centered">
          <div className="heading">
            {tag && <span className="tag">{tag}</span>}
            <h2>{heading}</h2>
            <p className="mono">{subheading}</p>
          </div>

          <div className="all-connections all-boxes-sec">
            {allConnections.map((single, i) => (
              <div className="single-connection box-sec" key={i}>
                <div className="content">
                  <h3>{single.title}</h3>
                  <p>{single.para}</p>
                  <div className="img">
                    <GatsbyImage
                      image={single.icon.asset.gatsbyImageData}
                      alt={single.title}
                      className="invert-color"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
