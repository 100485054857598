import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero2 from '../components/Hero2';
import ClientLogos from '../components/about/ClientLogos';
import GraySection from '../components/GraySection';
import Connection from '../components/candidates/Connection';
import CandidateReviews from '../components/candidates/CandidateReviews';
import Specialize from '../components/Specialize';
import FeaturedJobs from '../components/FeaturedJobs';
import Community from '../components/home/Community';
import Blog from '../components/home/Blog';
import CtaBox from '../components/CtaBox';

export default function ForCandidatesPage({ data, location }) {
  const { title, text1, rotatingTexts2, heroImg } =
    data.sanityCandidatesPage.heroCandidates;
  const { connectingYou, seo } = data.sanityCandidatesPage;
  const { hitusup, blogSec } = data.sanityHomepage;

  return (
    <>
      <SEO
        title={seo.title && seo.title}
        description={seo.description && seo.description}
        image={seo.ogImage && seo.ogImage.asset.url}
        location={location}
      />

      <Layout candidates>
        <Hero2
          forHome
          title={title}
          textgrp1={text1}
          textgrp2={rotatingTexts2}
          heroImg={heroImg.asset.url}
          candidates
        />
        <ClientLogos noTopPad />
        <GraySection type2 navTag="For Candidates" id="for-candidates">
          <Connection allData={connectingYou} />
          <CandidateReviews />
          <div className="home-specialise">
            <Specialize />
            <FeaturedJobs />
          </div>
          <Community hitusup={hitusup} />
        </GraySection>
        <Blog title={blogSec.heading} para={blogSec.subheading} />
        <CtaBox />
      </Layout>
    </>
  );
}

export const query = graphql`
  {
    sanityCandidatesPage {
      heroCandidates {
        title
        text1
        rotatingTexts2
        heroImg {
          asset {
            gatsbyImageData
            url
          }
        }
      }
      connectingYou {
        tag
        heading
        subheading
        boxCard {
          title
          para
          icon {
            asset {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        title
        description
        ogImage {
          asset {
            url
          }
        }
      }
    }
    sanityHomepage {
      hitusup {
        heading
        subheading
      }
      blogSec {
        heading
        subheading
      }
    }
  }
`;
