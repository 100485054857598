import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import IconStar from '../icons/IconStar';
import IconLocation from '../icons/IconLocation';
import IconTimer from '../icons/IconTimer';
import IconMoney from '../icons/IconMoney';

export default function FeaturedJobs() {
  const data = useStaticQuery(graphql`
    {
      allSanityJobs(
        limit: 5
        sort: { fields: datePosted, order: DESC }
        filter: { featured: { eq: true }, filled: { ne: true } }
      ) {
        nodes {
          slug {
            current
          }
          title
          short
          location {
            loc
          }
          date: datePosted(formatString: "MMM D, YYYY")
          datePosted
          dateExpiry
          category {
            categoryName
          }
          workplace
          jobType
          salary
        }
      }
    }
  `);
  const allJobs = data.allSanityJobs.nodes;

  const today = new Date().toISOString().slice(0, 10);
  const allJobsTillToday = allJobs.filter(
    (job) => job.datePosted <= today && job.dateExpiry >= today
  );

  return (
    <>
      <div className="featured-jobs">
        <div className="wrapper centered">
          <span className="tag">
            <IconStar />
            Featured jobs
            <IconStar />
          </span>

          <div className="all-jobs">
            {allJobsTillToday.map((job, i) => (
              <Link
                to={`/jobs/${job.slug.current}`}
                className="job-card"
                key={i}
              >
                <div className="top">
                  <h3>{job.title}</h3>
                  <div className="tags">
                    {job.workplace && (
                      <span className="job-tag remote">
                        {job.workplace === 'onsite' && 'On-site'}
                        {job.workplace === 'hybrid' && 'Hybrid'}
                        {job.workplace === 'remote' && 'Remote'}
                      </span>
                    )}
                    {job.jobType === 'fulltime' ? (
                      <span className="job-tag fulltime">Full time</span>
                    ) : (
                      <span className="job-tag contract">Contract</span>
                    )}
                  </div>
                </div>

                <div className="bottom">
                  <div className="bottom-left">
                    <div className="info location">
                      <IconLocation />
                      <p>{job.location.loc}</p>
                    </div>
                    <div className="info date">
                      <IconTimer />
                      <p>Posted: {job.date}</p>
                    </div>
                    {job.salary && (
                      <div className="info salary">
                        <IconMoney />
                        <p>{job.salary}</p>
                      </div>
                    )}
                  </div>

                  <div className="bottom-right category">
                    <p>{job.category.categoryName}</p>
                  </div>
                </div>

                <div className="bottom-end">
                  <p>{job.short}</p>
                  <span className="fake-button">View job</span>
                </div>
              </Link>
            ))}
          </div>

          <Link to="/jobs" className="button outline">
            View all open Roles
          </Link>
        </div>
      </div>
    </>
  );
}
