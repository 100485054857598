import React, { useRef } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
/* eslint-disable */
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
/* eslint-enable */

import ArrowLeft from '../images/ico-arrow-left.svg';
import Category2 from '../images/cat-2.svg';

SwiperCore.use([Navigation]);

export default function Specialize() {
  const data = useStaticQuery(graphql`
    {
      allSanityCategory(sort: { order: ASC, fields: categoryName }) {
        nodes {
          categoryName
          icon {
            asset {
              gatsbyImageData
            }
          }
        }
      }
      allSanityJobs {
        nodes {
          datePosted
          dateExpiry
          category {
            categoryName
          }
        }
      }
    }
  `);

  const allCategories = data.allSanityCategory.nodes;
  const allJobsRaw = data.allSanityJobs.nodes;
  const today = new Date().toISOString().slice(0, 10);
  const allJobsTillToday = allJobsRaw.filter(
    (job) => job.datePosted <= today && job.dateExpiry >= today
  );

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  // Check if window is undefined
  const isBrowser = typeof window !== 'undefined';
  function handleCatSelection(cat) {
    const abc = allJobsTillToday.filter((a) => a.category.categoryName === cat);
    if (isBrowser) {
      if (abc.length > 0) {
        window.currentState.category = cat;
      } else window.currentState.category = 'all';
      window.currentState.location = 'all';
    }
  }

  return (
    <>
      <div className="specialize">
        <div className="wrapper centered">
          {/* <div className="heading">{secTitle && <h2>{secTitle}</h2>}</div> */}
          <div className="heading">
            <h2>We specialise in</h2>
          </div>

          <div className="all-categories">
            <Swiper
              className="category-swiper"
              modules={Navigation}
              slidesPerView="auto"
              spaceBetween={20}
              centeredSlides
              grabCursor
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
              }}
              loop
            >
              {allCategories.map((cat, i) => (
                <SwiperSlide className="category" key={i}>
                  <Link
                    to="/jobs"
                    className="category-content"
                    // onClick={() => {
                    //   window.currentState = initialState;
                    // }}
                    onClick={() => handleCatSelection(cat.categoryName)}
                  >
                    <h3>{cat.categoryName}</h3>
                    <div className="img">
                      {cat.icon ? (
                        <GatsbyImage
                          image={cat.icon.asset.gatsbyImageData}
                          alt={cat.categoryName}
                          className="invert-color"
                        />
                      ) : (
                        <img
                          src={Category2}
                          alt={cat.categoryName}
                          className="invert-color"
                        />
                      )}
                    </div>
                  </Link>
                </SwiperSlide>
              ))}

              <div className="arrows">
                <div className="btn-wrap">
                  <button className="icon prev" type="button" ref={prevRef}>
                    <img src={ArrowLeft} alt="prev" />
                  </button>
                </div>
                <div className="btn-wrap">
                  <button className="icon next" type="button" ref={nextRef}>
                    <img src={ArrowLeft} alt="next" />
                  </button>
                </div>
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}
