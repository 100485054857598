import React, { useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
/* eslint-disable */
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
/* eslint-enable */

import { GatsbyImage } from 'gatsby-plugin-image';
import ArrowLeft from '../../images/ico-arrow-left.svg';

SwiperCore.use([Navigation]);

export default function CandidateReviews() {
  const data = useStaticQuery(graphql`
    {
      allSanityCandidateReviews(sort: { fields: order, order: ASC }) {
        nodes {
          name
          occupation
          quote
          # pic {
          #   asset {
          #     gatsbyImageData
          #   }
          # }
        }
      }
    }
  `);
  const allCandidates = data.allSanityCandidateReviews.nodes;
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <>
      <div className="candidate-reviews">
        <div className="wrapper">
          <div className="all-reviews">
            <Swiper
              className="candidate-swiper"
              modules={Navigation}
              slidesPerView="auto"
              spaceBetween={40}
              centeredSlides
              grabCursor
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
              }}
              loop
            >
              {allCandidates.map((candidate, i) => (
                <SwiperSlide className="review" key={i}>
                  <div className="heading">
                    {/* <div className="pic">
                      <GatsbyImage
                        image={candidate.pic.asset.gatsbyImageData}
                        alt={candidate.name}
                      />
                    </div> */}
                    <div className="title-texts">
                      <h3>{candidate.name}</h3>
                      <p className="mono">{candidate.occupation}</p>
                    </div>
                  </div>
                  <div className="review-text">
                    <p className="comment">{candidate.quote}</p>
                  </div>
                </SwiperSlide>
              ))}

              <div className="arrows">
                <button className="icon prev" type="button" ref={prevRef}>
                  <img src={ArrowLeft} alt="prev" />
                </button>
                <button className="icon next" type="button" ref={nextRef}>
                  <img src={ArrowLeft} alt="next" />
                </button>
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}
